<template>
  <div class="update-iost-campaign-config-container wrapper">
    <CRow>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <h1>{{ $t('IOST_CAMPAIGN_CONFIG_PAGE_TITLE') }}</h1>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <CRow>
              <CCol md="9" ld="9">
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <CForm @submit.prevent="handleSubmit(onSubmit)">
                    <ValidationProvider name="IOST_CAMPAIGN_CONFIG_PAGE_IS_ENABLED_FIELD_NAME" rules>
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label class>{{ $t('IOST_CAMPAIGN_CONFIG_PAGE_IS_ENABLED_LABEL') }}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <div class="btn-switch-wrapper">
                            <label class="btn-switch">
                              <input type="checkbox" class="checkbox" v-model="campaignDetails.isActive" />
                              <span class="check-silder"></span>
                            </label>
                          </div>
                          <span class="error-msg-wrap">
                            <span class="error-msg">{{ errors[0] }}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>

                    <ValidationProvider name="IOST_CAMPAIGN_CONFIG_PAGE_MAXIMUM_USERS_FIELD_NAME" rules="required|min_value:1">
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label>{{ $t('IOST_CAMPAIGN_CONFIG_PAGE_MAXIMUM_USERS_LABEL') }}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <div class="input-group">
                            <currency-input
                              class="input-field form-control"
                              v-model="campaignDetails.settings.maximumUsers"
                              :precision="0"
                            />
                          </div>

                          <div class="error-msg-wrap">
                            <span class="error-msg">{{ errors[0] }}</span>
                          </div>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                    <ValidationProvider name="IOST_CAMPAIGN_CONFIG_PAGE_APP_VERSION_FIELD_NAME" rules="required|semver">
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="3" md="3" class="label-group">
                          <label>{{ $t('IOST_CAMPAIGN_CONFIG_PAGE_APP_VERSION_LABEL') }}:</label>
                        </CCol>
                        <CCol lg="9" md="9">
                          <div class="input-group">
                            <input
                              class="input-field form-control"
                              v-model="campaignDetails.settings.appVersion"
                            />
                          </div>

                          <div class="error-msg-wrap">
                            <span class="error-msg">{{ errors[0] }}</span>
                          </div>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                    <CRow class="form-actions mt-3">
                      <CCol lg="12" md="12">
                        <CButton v-if="isAllowedUpdating" color="primary" class="btn btn-primary" @click="onSubmit">{{
                          $t('IOST_CAMPAIGN_CONFIG_PAGE_BUTTON_SAVE')
                        }}</CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </ValidationObserver>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import responseHelper from '@/utils/response-helper';
import { endpoints, env } from '@/constants';

export default {
  name: 'IOSTCampaignConfig',
  components: {
    VclList,
  },
  data() {
    return {
      config: {
        isActive: false,
        maximumUsers: null
      },
      isLoading: true,
      isProcessing: false,
      isAllowedUpdating: false,
      errorMessage: null,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    await Promise.all([this.getCampaignDetails()]);
    this.isLoading = false;
  },
  methods: {
    checkPermissions() {
      this.userPermissions.forEach((item) => {
        if (item.name === 'UPDATE_CAMPAIGN') {
          this.isAllowedUpdating = true;
        }
      });
    },
    async getCampaignDetails() {
      try {
        const result = await this.$http.get(endpoints.getCampaignDetails('IOST'), {});
        this.campaignDetails = result.data;
        this.campaignDetails.isActive = this.campaignDetails.status === 'ACTIVE';
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('IOST_CAMPAIGN_CONFIG_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onSubmit() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid || this.errorMessage) {
          return;
        }

        this.update();
      });
    },
    async update() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          name: this.campaignDetails.name,
          status: this.campaignDetails.isActive ? 'ACTIVE' : 'INACTIVE',
          settings: {
            maximumUsers: this.campaignDetails.settings.maximumUsers,
            appVersion: this.campaignDetails.settings.appVersion
          },
        };
        const result = await this.$http.put(endpoints.updateCampaign('IOST'), data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('IOST_CAMPAIGN_CONFIG_PAGE_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('IOST_CAMPAIGN_CONFIG_PAGE_NOTIFY_SUCCESS_MESSAGE'),
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('IOST_CAMPAIGN_CONFIG_PAGE_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-iost-campaign-config-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #3e4b65;
        padding-top: 15px;
        margin-bottom: 15px;
      }
    }
    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .label-group {
      text-align: right;
      label {
        font-size: 14px;
        font-weight: 300;
        color: #3e4b65;
      }
    }

    .input-group {
      input {
        flex: 0 0 50%;
        width: 50%;
        font-size: 16px;
        border-radius: 4px !important;
        color: #3a4a7f;
      }
      .input-group-prepend {
        .input-group-text {
          width: 65px;
          background-color: unset;
          border: none;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 36px;
        padding: 0 30px;
        color: #fff;
        margin: 30px 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }

  .ada-notification-histories-container {
    h3 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }
  }
}
</style>
