var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-iost-campaign-config-container wrapper"},[_c('CRow',[_c('CCol',{attrs:{"lg":"12","md":"12"}},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('IOST_CAMPAIGN_CONFIG_PAGE_TITLE')))])]),(_vm.isLoading)?_c('CCardBody',[_c('vcl-list')],1):_vm._e(),(!_vm.isLoading)?_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"9","ld":"9"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('ValidationProvider',{attrs:{"name":"IOST_CAMPAIGN_CONFIG_PAGE_IS_ENABLED_FIELD_NAME","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',{},[_vm._v(_vm._s(_vm.$t('IOST_CAMPAIGN_CONFIG_PAGE_IS_ENABLED_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"btn-switch-wrapper"},[_c('label',{staticClass:"btn-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campaignDetails.isActive),expression:"campaignDetails.isActive"}],staticClass:"checkbox",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.campaignDetails.isActive)?_vm._i(_vm.campaignDetails.isActive,null)>-1:(_vm.campaignDetails.isActive)},on:{"change":function($event){var $$a=_vm.campaignDetails.isActive,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.campaignDetails, "isActive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.campaignDetails, "isActive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.campaignDetails, "isActive", $$c)}}}}),_c('span',{staticClass:"check-silder"})])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"IOST_CAMPAIGN_CONFIG_PAGE_MAXIMUM_USERS_FIELD_NAME","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('IOST_CAMPAIGN_CONFIG_PAGE_MAXIMUM_USERS_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"input-group"},[_c('currency-input',{staticClass:"input-field form-control",attrs:{"precision":0},model:{value:(_vm.campaignDetails.settings.maximumUsers),callback:function ($$v) {_vm.$set(_vm.campaignDetails.settings, "maximumUsers", $$v)},expression:"campaignDetails.settings.maximumUsers"}})],1),_c('div',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"IOST_CAMPAIGN_CONFIG_PAGE_APP_VERSION_FIELD_NAME","rules":"required|semver"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"3","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t('IOST_CAMPAIGN_CONFIG_PAGE_APP_VERSION_LABEL'))+":")])]),_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.campaignDetails.settings.appVersion),expression:"campaignDetails.settings.appVersion"}],staticClass:"input-field form-control",domProps:{"value":(_vm.campaignDetails.settings.appVersion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.campaignDetails.settings, "appVersion", $event.target.value)}}})]),_c('div',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)}),_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('IOST_CAMPAIGN_CONFIG_PAGE_BUTTON_SAVE')))]):_vm._e()],1)],1)],1)]}}],null,false,4218227454)})],1)],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }